@charset "UTF-8";
@import url(https://fonts.googleapis.com/earlyaccess/notosansjp.css);
@import-normalize; /* bring in normalize.css styles */


* {
    font-family: "Noto Sans JP", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Hiragino Kaku Gothic ProN", "メイリオ", meiryo, sans-serif;
}

body {
    margin: 0px;
}